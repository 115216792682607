@import './Variables.scss';

//GENERAL 

body{
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: $OpenSans;
  background-color: map-get($theme-colors, "background");
}

.corpo{
  position: relative;
  height: 100vh;
  width: calc(100% - 130px);
  padding: 0px 32px 40px 32px;
  float: left;
  overflow-y: scroll;
  @media only screen and (max-width: 991px){
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0px 16px 40px 16px;
  }
}

//CENTRATURA ELEMENTI

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

// BACKGROUND

.u-bg-light{
  background-color: map-get($theme-colors, "white");
}

// CONT SHADOW - CARD

.card{
  &.login-card{
      margin-top: -80px;
      max-width: 600px;
      @media only screen and (max-width: 575px){
          margin-top: -40px;
      }
      .card-header{
          background-color: map-get($theme-colors, "white");
          border: none;
          text-align: center;
          padding-top: 30px;
          .card-title{
              font-size: 20px;
              color: map-get($theme-colors, "darkborder");
              font-weight: 500;
              span{
                  color: map-get($theme-colors, "black");
                  font-weight: 800;
              }
          }
      }
      .card-body{
          padding-right: 40px !important;
          padding-left: 40px !important;
          .btn{
              min-width: 200px;
          }
      }
      @media only screen and (max-width: 991px){
        .card-body{
          padding-right: 16px !important;
          padding-left: 16px !important;
        }
      }
  }
}

.card{
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  border-radius: 20px;
  border: none;
  overflow: hidden;
  margin-bottom: 32px;
  .card-header{
    background-color: map-get($theme-colors, "primary");
    border: 0;
    .card-title{
      margin-bottom: 0;
      color: map-get($theme-colors, "white");
      font-weight: 700;
    }
  }
  .card-body{
    padding: 16px 21px !important;
  }
  .card-footer{
    background-color: map-get($theme-colors, "grey");
    border-top: 1px solid map-get($theme-colors, "darkborder");
    padding-top: 24px;
    padding-bottom: 24px;
    color: map-get($theme-colors, "darkborder");
    .row{
      .col-6{
        &:first-child{
          font-weight: 700;
        }
        &:last-child{
          a{
            color:  map-get($theme-colors, "primary")
          }
        }
      }
    }
  }
}

.cont-white-shadow{
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  padding: 30px 15px;
  border-radius: 20px;
 
}

// FORM E LABEL

form{
  .form-group{
    margin-bottom: 24px;
    label{
      color: map-get($theme-colors, "darkborder");
      font-size: 12px;
    }
    input,
    textarea,
    select{
      border: 0;
      border-bottom: 2px solid map-get($theme-colors, "primary-contrast");
      border-radius: 0;
      font-weight: 600;
      color: map-get($theme-colors, "black");
      background-color: transparent;
      &:focus{
        box-shadow: none;
        border-color: map-get($theme-colors, "primary");
        background-color: transparent;
      }
    }
    .form-text{
      font-size: 10px;
      color: map-get($theme-colors, "grey");
    }
    .custom-control-label{
      font-style: italic;
    }
    .custom-control-input:checked~.custom-control-label::before{
      border-color: map-get($theme-colors, "primary-contrast");
      background-color: map-get($theme-colors, "primary-contrast");
    }
  }
  @media only screen and(max-width: 1564px){
    &.filter{
      .form-group{
        width: 20%;
        padding-right: 8px;
        label{
          width: 100%;
          justify-content: left;
        }
        select{
          width: 100%;
          justify-content: left;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
        input{
          width: 100%;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }
      }
    }
  }
  @media only screen and(max-width: 837px){
    &.filter{
      .form-group{
        width: 33.3%;
        // &:nth-child(4),
        // &:last-child{
        //   margin-top: 10px;
        // }
      }
    }
  }
  @media only screen and(max-width: 575px){
    &.filter{
      .form-group{
        margin-bottom: 0;
        label{
          margin-bottom: 0;
        }
        // input{
        //   overflow: hidden;
        // }
      }
    }
  }
}

.cont-p-filters{
  padding-right: 197px;
  @media only screen and (max-width: 1196px){
    padding-right: 0;
  }
}

// BUTTON

.btn{
  background-color: map-get($theme-colors, "primary-contrast");
  color:map-get($theme-colors, "white");
  border: 0;
  border-radius: 20px;
  padding: 7px 25px !important;
  margin-top: 16px;
  font-weight: 600;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  padding: 30px 15px;
  font-size: 20px;
  &:hover{
    background-color: map-get($theme-colors, "primary");
    color:map-get($theme-colors, "white");
  }
  img{
    width: 25px;
    margin-right: 7px;
  }
}

.btn-plus{
  background-color: map-get($theme-colors, "primary");
  font-size: 15px;
  margin-top: 0;
  img{
    width: 20px;
  }
}

.btn-collapse{
  margin-top: 0;
  margin-bottom: 0 !important;
  font-size: 16px;
  color: map-get($theme-colors, "primary");
  background-color: white;
  &:focus{
    -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
    outline: none;
  }
}

.position-btn-filter{
  position: absolute;
  top: 0;
  right: 16px;
  @media only screen and (max-width: 1196px){
    position: relative;
    top: inherit;
    right: inherit;
    width: 100%;
  }
  @media only screen and (max-width: 575px){
    margin-top: 0;
  }
}

.resp-btn{
  @media only screen and (max-width: 991px){
    margin-top: 15px;
  }
  @media only screen and (max-width: 575px){
    margin-top: 0;
  }
}

// TEXT

h4{
  color: map-get($theme-colors, "primary");
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 16px;
}

.no-activities{
  color: map-get($theme-colors, "primary");
  text-align: center;
  font-weight: 600;
}

// MARGIN - PAGGING

.m-b-30{
  margin-bottom: 30px;
}

.m-b-50{
  margin-bottom: 50px;
}

.m-t-20{
  margin-top: 20px;
}

// CONT-GREY

.cont-grey{
  background-color: map-get($theme-colors, "background");
  margin-bottom: 20px;
  .cont-grey-header{
    padding: 24px 16px;
    border-bottom: 1px solid map-get($theme-colors, "border");
    .col-6{
      &:first-child{
        h6{
          color: map-get($theme-colors, "primary");
          font-weight: 700;
          font-size: 17px;
          margin-bottom: 0;
          img{
            margin-right: 10px;
          }
        }
      }
      &:last-child{
        h6{
          color: map-get($theme-colors, "primary-contrast");
          font-size: 17px;
          margin-bottom: 0;
        }
      }
    }
  }
  .cont-grey-body{
    padding: 16px;
    color: map-get($theme-colors, "black");
    p{
      margin-bottom: 0;
    }
  }
}

.error-message {
  color: map-get($theme-colors, "danger") !important;
}

.title-header{
  .btn-plus{
    margin-right: 20px;
  }
  .btn-trash{
    margin-top: 0;
    margin-left: 20px;
    padding: 7px 10px 8px !important;
    border-radius: 50%;
    img{
      margin-right: 0;
      margin-top: -4px;
    }
  }
}

// NAV TAB

.nav-tabs{
  border-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  .nav-item{
    margin-bottom: 0;
    margin-right: 20px;
    .nav-link{
      border: 0;
      border-bottom: 3px solid transparent;
      font-weight: 700;
      opacity: 0.7;
      color:  map-get($theme-colors, "black");
      transition: 0.2s ease-in-out;
      &.active {
        background-color: transparent;
        border-radius: 0;
        border-bottom-color: map-get($theme-colors, "primary-contrast");
        opacity: 1;
      }
    }
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      cursor: pointer;
      .nav-link{
        opacity: 1;
      }
    }
  }
}

// CARD LOG, REGISTRA UN'AZIONE

.card-log{
  .card-header{
    padding: 0;
    .nav-tabs{
      padding: 0;
      .nav-item{
        width: 50%;
        margin-right: 0;
        .nav-link{
          padding-top: 20px;
          padding-bottom: 20px;
          color: map-get($theme-colors, "white");
          text-transform: uppercase;
          &.active{
            color: map-get($theme-colors, "primary-contrast");
          }
          .badge{
            background-color: map-get($theme-colors, "danger");
            font-size: 14px;
            border-radius: 50%;
            padding-top: 7px;
            padding-top: 7px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .card-body{
    height: calc(100vh - 300px);
    overflow-y: scroll;
    @media only screen and (max-width: 991px) {
      height: auto;
      overflow: inherit;
    }
  }
}

.sales-card{
  .card-header{
    text-align: left;
    color: map-get($theme-colors, "white");
    padding: 20px 16px;
    h3{
      font-weight: 700;
      margin-bottom: 10px;
      span{
        font-weight: 300;
      }
      .editable-textarea, .editable-currency {
        padding: 0;
        height: 47px;
        margin-top: -10px;
        &:focus{
          background-color: rgba( map-get($theme-colors, "white"), 0.6);
          color:  map-get($theme-colors, "white");
          border: 1px solid  map-get($theme-colors, "white");
          padding-left: 5px;
          padding-right: 5px;
          box-shadow: none;
        }
      }
      @media only screen and (max-width: 700px){
        font-size: 24px;
      }
    }
    p{
      margin-bottom: 8px;
      span{
        font-weight: 700;
        color: map-get($theme-colors, "primary-contrast");
      }
      &.closing-date{
        width: calc(100% - 112px);
        float: left;
      }
    }
  }
  .card-body{
    padding: 0 !important;
    .cont-btn-log{
      padding: 10px 16px;
      border-bottom: 0.5px solid #C9C9C9;
      .btn{
        margin-top: 0 !important;
        font-size: 12px;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        min-width: inherit;
        font-weight: 700;
        &:hover{
          opacity: 0.6;
        }
      }
    }
    .cont-info{
      padding: 20px 16px;
      color: map-get($theme-colors, "primary");
      height: calc(100vh - 523px);
      overflow-y: scroll;
      &.cont-info-contact{
        height: calc(100vh - 380px);
      }
      ul{
        padding-left: 16px;
      }
      p{
        a{
          color: map-get($theme-colors, "primary");
          text-decoration: underline;
          transition: 0.2s;
          &:hover{
            color: map-get($theme-colors, "primary-contrast");
          }
        }
      }
      @media only screen and (max-width: 991px){
        height: auto;
        overflow-y: inherit;
      }
    }
  }
}

.btn-call{
  background-color: map-get($theme-colors, "pink");
  color: map-get($theme-colors, "primary")
}

.btn-email{
  background-color: map-get($theme-colors, "primary-contrast");
}

.btn-meeting{
  background-color: map-get($theme-colors, "primary");
}

.edit-element{
  position: absolute;
  right: 32px;
  .btn{
    margin-top: 0;
    img{
      margin-right: 0;
    }
    &.btn-trash{
         padding: 6px 12px 7px !important;
         border-radius: 50%;
         img{
           margin-right: 0;
           margin-top: -4px;
           width: 20px;
         }
    }
    &:first-child{
      background-color: #9DACE3;
      margin-right: 8px;
    }
  }
  @media only screen and (max-width: 991px){
    right: 16px;
  }
}

// CARD ACTIVITIES

.card-activities{
  .card-header{
    padding: 16px;
    color: map-get($theme-colors, "white");
    font-weight: 700;
  }
}

.appointment_scheduled{
  background-color: map-get($theme-colors , "appointment_scheduled" );
}
.qualified_to_buy {
  background-color: map-get($theme-colors , "qualified_to_buy" );
}
.presentation_scheduled {
  background-color: map-get($theme-colors , "presentation_scheduled" );
}
.contract_sent {
  background-color: map-get($theme-colors , "contract_sent" );
}
.closed_won {
  background-color: map-get($theme-colors , "closed_won" );
}
.closed_lost {
  background-color: map-get($theme-colors , "closed_lost" );
}
.freezed-badge{
  background-color: map-get($theme-colors, "freezed");
  margin-left: 7px;
}
.u-text-right{
  text-align: right;
}

// REACT-DATE PICKER

.react-datepicker-wrapper{
  input{
    padding: 6px 12px;
    &:focus{
      box-shadow: none;
      outline: 0;
    }
  }
}

.react-datepicker{
  border: 1px solid map-get($theme-colors , "border" );
  font-family: $OpenSans;
  .react-datepicker__triangle{
    border-bottom-color: map-get($theme-colors , "border" );
  }
  .react-datepicker__header{
    border-bottom: 1px solid map-get($theme-colors , "border" );
    .react-datepicker__current-month{
      color: map-get($theme-colors , "primary-contrast" );
    }
    .react-datepicker__day-names{
      .react-datepicker__day-name{
        color: map-get($theme-colors , "black" );
      }
    }
  }
}

.react-datepicker__day{
  color: map-get($theme-colors , "black" )!important;
  &:hover{
    border-radius: 50%;
  }
}

.react-datepicker__day--today{
  border-radius: 50%;
}

.react-datepicker__day--selected{
  background-color: map-get($theme-colors , "primary-contrast" )!important;
  color: map-get($theme-colors , "white" )!important;
  border-radius: 50%;
}