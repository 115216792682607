@import "./../../Variables.scss";

.attachment-input-file {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.btn-add-attachment {
  font-size: 12px !important;
  min-width: inherit !important;
  margin-top: 0px !important;
  cursor: pointer;
}

.attachments-modal-spinner {
  text-align: center;
  .spinner-border {
    border-color: map-get($theme-colors, "primary-contrast");
    border-right-color: transparent;
  }
}

.attachment-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.attachment-btn-trash {
  display: inline-block;
  width: 24px;
  background-color: map-get($theme-colors, "primary-contrast");
  border-radius: 50%;
  margin-left: 12px;
  cursor: pointer;
  img {
    margin-left: 4px;
    margin-bottom: 4px;
    width: 16px;
  }
}

.attachment-modal-confirm {
  display: inline-block;
}

.attachment-content-modal {
  background-color: transparent;
  box-shadow: none !important;
}
