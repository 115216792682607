@import "./../../Variables.scss";

.action-url {
  margin-right: 7px;
  font-size: 16px;
}

.contact-deals-link {
  float: right;
  font-size: 12px !important;
  background-color: #9dace3 !important;
  color: map-get($theme-colors, "white") !important;
  margin-top: 0px !important;
}
