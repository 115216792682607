@import "./../../Variables.scss";

.sales-card {
  .react-datepicker__input-container input,
  input[type="date"] {
    cursor: pointer;
    color: map-get($theme-colors, "primary");
    text-align: right;
    font-weight: bolder;
    border: none;
  }

  .search-value {
    cursor: pointer;
  }
}